import React, { useState } from "react";
import { logInWithEmailAndPassword, verifyToken } from "../api/firebase";
import { Link } from "react-router-dom";
import { useUser } from "../utils/UserContext";

export default function LoginPage() {
	const { currentUser, logout } = useUser();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [errorMessage, setErrorMessage] = useState("");

	const handleUserEmailChange = (e) => {
		setErrorMessage("");
		setEmail(e.target.value);
	};

	const handleUserPassChange = (e) => {
		setErrorMessage("");
		setPassword(e.target.value);
	};

	const handleLogin = async () => {
		const result = await logInWithEmailAndPassword(email, password);
		if (result.length) {
			setErrorMessage("Failed to login, recheck your login credentials");
		}
	};

	return (
		<div className="page-login-form-wrapper">
			{currentUser ? (
				<div>
					<h3>You are logged in....</h3>
					<p>
						<Link to="/" className="button">
							Click here to continue
						</Link>
					</p>
				</div>
			) : (
				<div className="page-login-form">
					<h1>LoginPage</h1>
					<p style={{ color: "red" }}>{errorMessage}</p>
					<input
						type="text"
						placeholder="email"
						value={email}
						onChange={handleUserEmailChange}
					/>
					<input
						type="password"
						placeholder="password"
						value={password}
						onChange={handleUserPassChange}
					/>
					<button onClick={handleLogin}>login</button>
				</div>
			)}
		</div>
	);
}
