import Layout from "../../components/Layout";
import { Link } from "react-router-dom";
import { SignInCheck } from "../../components/SignInCheck";
import { useEffect } from "react";

export default function Dashboard({ title }) {
	let content = <Content />;

	useEffect(() => {
		document.title = title; // Set the document title dynamically
	}, [title]);

	return <Layout content={content} />;
}

const Content = () => {
	return (
		<>
			<section className="bleezy-about-page section_50">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<h2>Dashboard</h2>

							<div className="dash-menu">
								<Link to="/blocks">Blocks</Link>
								<Link to="/posts">Posts</Link>
							</div>
						</div>
					</div>
				</div>
			</section>
			<SignInCheck />
		</>
	);
};
