import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const SignInCheck = () => {
	const navigate = useNavigate();

	useEffect(() => {
		let token = localStorage.getItem("accessToken");
		if (token === null) {
			// redirect to page not found
			navigate("/page-not-found");
		}
	}, []);

	return <div></div>;
};
