import React, { useEffect } from "react";
import Layout from "../components/Layout";

import image from "../img/980x551.png";

export default function AboutPage({ title }) {
	let content = <Content />;

	useEffect(() => {
		document.title = title; // Set the document title dynamically
	}, [title]);

	return <Layout content={content} />;
}

const Content = () => {
	return (
		<>
			<section className="bleezy-breadcromb-area">
				<div className="breadcromb-top section_50">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<div className="breadcromb-top-text">
									<h2 style={{ textTransform: "none" }}>
										S&N Training and Services
									</h2>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="breadcromb-bottom">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<div className="breadcromb-bottom-text">
									<ul>
										<li>
											<a href="index.html">home</a>
										</li>
										<li>
											<a href="#">
												<i className="fa fa-long-arrow-right"></i>
											</a>
										</li>
										<li>about</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="bleezy-about-page section_50">
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<div className="about-left">
								<h2>About Us</h2>
								<p>
									With over two decades of military background
									and experience in the security and first aid
									industry, I have worked as both an operator
									and trainer in the UK and abroad.
								</p>
								<p>
									I have served as a tutor for a number of
									private security companies in the UK, and
									have also worked as a Military Venue
									Security Forces train the trainer and
									inspectorate role on Common Wealth Game in
									2014. As an instructor, mentor, and assessor
									for more than 20 years in a military
									environment, I have been chosen to teach and
									train students in a friendly environment
									that fulfills their individual learning
									needs. I believe in the "train hard, fight
									easy" principle, and focus on delivering
									qualitative products rather than
									quantitative ones.
								</p>
								<p>
									My courses emphasize professionalism in
									future job roles, with a high level of
									training in both theory and practical
									scenario-based exercises.
								</p>
								<p>
									Thank you for considering our security
									officer training courses. We look forward to
									helping you achieve your goals and prepare
									for a successful career in the security
									industry.
								</p>
							</div>
						</div>
						<div className="col-md-6">
							<div className="about-right">
								<img src={image} alt="about image" />
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};
