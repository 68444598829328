import { useEffect, useState } from "react";
import Modal from "react-modal";
import { MdEditSquare } from "react-icons/md";
import {
	collection,
	query,
	getDocs,
	updateDoc,
	doc,
	addDoc,
} from "firebase/firestore";

import Layout from "../../components/Layout";
import { useUser } from "../../utils/UserContext";
import { db } from "../../utils/firebase";
import ContactCreateForm from "./forms/CreateForm";

import { modalStyle } from "../../utils/style";

export default function ContactPage({ pageTitle }) {
	const COLLECTION_NAME = "contacts";
	Modal.setAppElement("#root");
	const waitTime = 1200;

	const { currentUser } = useUser();
	const [contacts, setContacts] = useState([]);
	const [editContact, setEditContact] = useState(null);

	const [modalIsOpen, setIsOpen] = useState(false);
	const [addFormIsOpen, setAddFormIsOpen] = useState(false);
	const [formIsProcessing, setFormIsProcessing] = useState(false);

	const fetchContact = async () => {
		const q = query(collection(db, COLLECTION_NAME));
		const querySnapshot = await getDocs(q);
		let list = [];
		querySnapshot.forEach((doc) => {
			let item = {
				docId: doc.id,
				...doc.data(),
			};
			list.push(item);
		});

		setContacts(list);
	};

	const updateDocument = async (collectionName, docId, newData) => {
		try {
			const docRef = doc(db, collectionName, docId);
			await updateDoc(docRef, newData);
		} catch (error) {
			console.error("Error updating document: ", error);
		}
	};

	const handleUpdate = async () => {
		setFormIsProcessing(true);

		const data = {
			city: editContact.city,
			address: editContact.address,
			phone: editContact.phone,
			email: editContact.email,
		};
		await updateDocument(COLLECTION_NAME, editContact.docId, data);

		setFormIsProcessing(false);
		closeModal();

		reloadData();
	};

	const reloadData = () => {
		setTimeout(async () => {
			await fetchContact();
			setFormIsProcessing(false);
		}, waitTime);
	};

	const afterOpenModal = () => {
		// references are now sync'd and can be accessed.
		// subtitle.style.color = '#f00';
	};

	const closeModal = () => {
		setIsOpen(false);
	};

	const closeAddModal = () => {
		setAddFormIsOpen(false);
	};

	const handleEdit = (contact) => {
		setEditContact(contact);
		setIsOpen(true);
	};

	const handleCityChange = (e) => {
		const val = e.target.value;
		setEditContact((prev) => ({
			...prev,
			city: val,
		}));
	};

	const handleAddressChange = (e) => {
		const val = e.target.value;
		setEditContact((prev) => ({
			...prev,
			address: val,
		}));
	};

	const handlePhoneChange = (e) => {
		const val = e.target.value;
		setEditContact((prev) => ({
			...prev,
			phone: val,
		}));
	};

	const handleEmailChange = (e) => {
		const val = e.target.value;
		setEditContact((prev) => ({
			...prev,
			email: val,
		}));
	};

	const handleAddClick = () => {
		setAddFormIsOpen(true);
	};

	let content = (
		<Content
			currentUser={currentUser}
			contacts={contacts}
			onEditClick={handleEdit}
			onCreateClick={handleAddClick}
		/>
	);

	useEffect(() => {
		document.title = pageTitle;
	}, [pageTitle]);

	useEffect(() => {
		fetchContact();
	}, []);

	return (
		<div>
			<Layout content={content} />

			<Modal
				isOpen={addFormIsOpen}
				onAfterOpen={afterOpenModal}
				onRequestClose={closeAddModal}
				style={modalStyle}
				contentLabel="Create Contact"
			>
				<ContactCreateForm
					onCloseClick={closeAddModal}
					onCreateComplete={() => {
						reloadData();
						closeAddModal();
					}}
				/>
			</Modal>

			<Modal
				isOpen={modalIsOpen}
				onAfterOpen={afterOpenModal}
				onRequestClose={closeModal}
				style={modalStyle}
				contentLabel="Update Contact"
			>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						width: 400,
						gap: 10,
						position: "relative",
					}}
				>
					{editContact && (
						<>
							<input
								type="text"
								placeholder="City"
								value={editContact.city}
								onChange={handleCityChange}
							/>
							<textarea
								type="text"
								placeholder="Address"
								value={editContact.address}
								onChange={handleAddressChange}
							/>
							<input
								type="text"
								placeholder="Phone"
								value={editContact.phone}
								onChange={handlePhoneChange}
							/>
							<input
								type="text"
								placeholder="Email"
								value={editContact.email}
								onChange={handleEmailChange}
							/>
							<button
								onClick={handleUpdate}
								disabled={formIsProcessing}
							>
								Update
							</button>
							<button
								onClick={closeModal}
								style={{
									backgroundColor: "#f26723",
									color: "white",
									border: "none",
								}}
							>
								Cancel
							</button>
						</>
					)}
				</div>
			</Modal>
		</div>
	);
}

const Content = ({ currentUser, contacts, onEditClick, onCreateClick }) => {
	const renderlist = contacts.map((contact) => {
		return (
			<div className="col-md-4 col-sm-4" key={contact.docId}>
				<div className="single-contact-address">
					<h3>
						{contact.city}
						{currentUser && (
							<MdEditSquare
								size={22}
								style={{ cursor: "pointer" }}
								onClick={() => onEditClick(contact)}
							/>
						)}
					</h3>
					<ul>
						<li>
							<i className="fa fa-map-marker"></i>
							<p>{contact.address}</p>
						</li>
						<li>
							<i className="fa fa-phone"></i>
							<p>{contact.phone}</p>
						</li>
						<li>
							<i className="fa fa-envelope-o"></i>
							<p>{contact.email}</p>
						</li>
					</ul>
				</div>
			</div>
		);
	});

	return (
		<>
			<section className="bleezy-breadcromb-area">
				<div className="breadcromb-top section_50">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<div className="breadcromb-top-text">
									<h2>Contact Us</h2>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="breadcromb-bottom">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<div className="breadcromb-bottom-text">
									<ul>
										<li>
											<a href="index.html">home</a>
										</li>
										<li>
											<a href="#">
												<i className="fa fa-long-arrow-right"></i>
											</a>
										</li>
										<li>contact</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="bleezy-contact-page-area section_t_100 section_b_70">
				<div className="container">
					{currentUser && (
						<div style={{ marginBottom: 10 }}>
							<button
								style={{
									backgroundColor: "#f26723",
									border: "none",
									color: "white",
								}}
								onClick={onCreateClick}
							>
								Add contact
							</button>
						</div>
					)}
					<div className="row">{renderlist}</div>
				</div>
			</section>
		</>
	);
};
