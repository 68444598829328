import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../api/firebase";
import { Helmet } from "react-helmet";

import Layout from "../components/Layout";
import { formatDate } from "../utils/dates";

export default function SingleBlogPage({ pageTitle }) {
	const { slug } = useParams();
	const [post, setPost] = useState();

	let content = <Content post={post} />;

	const getPost = async () => {
		const docRef = doc(db, "posts", slug);
		const docSnap = await getDoc(docRef);

		if (docSnap.exists()) {
			let item = {
				id: docSnap.id,
				...docSnap.data(),
			};
			item.date = formatDate(item.date);
			setPost(item);
		} else {
			// no document
		}
	};

	useEffect(() => {
		if (slug === undefined) return;

		setTimeout(() => {
			getPost();
		}, 1200);
	}, []);

	useEffect(() => {
		document.title = pageTitle;
	}, [pageTitle]);

	return (
		<>
			<Layout content={content} />
		</>
	);
}

const Content = ({ post }) => {
	if (post === undefined) return <Loading />;

	return (
		<>
			<Helmet>
				<meta
					property="og:url"
					content={`https://www.sntrainingandservices.com/blogs/${post.id}`}
				/>
				<meta property="og:title" content={post.title} />
				<meta property="og:description" content={post.summary} />
				<meta property="og:image" content={post.image} />
			</Helmet>
			<section className="bleezy-blog-page-area section_50 blog-detail">
				<div className="container">
					<div className="row">
						<div className="col-md-9">
							<div className="blog-page-left">
								<div className="blog-image">
									<img src={post.image} alt={post.summary} />
								</div>
								<div className="blog-date">
									<p>{post.date}</p>
								</div>
								<div
									dangerouslySetInnerHTML={{
										__html: post.body,
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

const Loading = () => {
	return (
		<section className="bleezy-blog-page-area section_100">
			<div className="container">
				<div className="row">
					<div
						className="col-md-12"
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							minHeight: 400,
						}}
					>
						<div className="lds-ripple ">
							<div></div>
							<div></div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
