import Modal from "react-modal";
import { MdClose } from "react-icons/md";

import { Block } from "@schema/Block";
import { useEffect, useState } from "react";

export const BlockModal = ({
	block,
	isOpen,
	onSubmit,
	afterOpenModal,
	closeModal,
}: Props) => {
	const [id, setId] = useState(block?.id ? block.id : "");
	const [name, setName] = useState(block?.name ? block.name : "");
	const [imageUrl, setImageUrl] = useState(
		block?.imageUrl ? block.imageUrl : ""
	);
	const minNameLength = 3;

	const formTitle =
		block?.id && block.id.length ? "Edit Block" : "Create Block";

	const handleNameChange = (e) => {
		setName(e.target.value);
	};

	const handleImageUrlChange = (e) => {
		setImageUrl(e.target.value);
	};

	const validateForm = () => {
		return name.length >= minNameLength;
	};

	const handleSubmit = () => {
		const id = block?.id ? block.id : "";

		if (!validateForm()) {
			alert(`Name must be at least ${minNameLength} characters.`);
			return;
		}

		onSubmit({
			...block,
			id,
			name,
			imageUrl,
		});
	};

	useEffect(() => {
		setId(block?.id ? block.id : "");
		setName(block?.name ? block.name : "");
		setImageUrl(block?.imageUrl ? block.imageUrl : "");
	}, [block]);

	return (
		<Modal
			isOpen={isOpen}
			onAfterOpen={afterOpenModal}
			onRequestClose={closeModal}
			style={customStyles}
			contentLabel="Block Modal"
		>
			<h3 style={{ marginBottom: "10px" }}>{formTitle}</h3>

			<div className="modal-close" onClick={() => closeModal()}>
				<MdClose />
			</div>

			<div>
				<input
					value={name}
					onChange={handleNameChange}
					style={styles.input}
					placeholder="Enter name here..."
				/>
				<input
					value={imageUrl}
					onChange={handleImageUrlChange}
					style={styles.input}
					placeholder="Enter full image url here..."
				/>
				<button onClick={handleSubmit} style={styles.button}>
					Submit
				</button>
			</div>
		</Modal>
	);
};

type Props = {
	block: Block;
	isOpen: boolean;
	afterOpenModal: () => void;
	closeModal: () => void;
	onSubmit: (block: Block) => void;
};

const customStyles = {
	position: "relative",
	overlay: {
		backgroundColor: "rgba(0,0,0,0.2)",
	},
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		width: "800px",
		height: "240px",
	},
};

const styles = {
	input: {
		width: "100%",
		padding: "6px 10px",
		margin: "10px 0px",
		border: "1px solid #ccc",
	},
	button: {
		display: "block",
		backgroundColor: "#f26723",
		border: "none",
		borderRadius: "20px",
		color: "white",
		padding: "5px 20px",
		margin: "5px 0px",
	},
};
