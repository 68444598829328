import React, { createContext, useContext, useState, useEffect } from "react";
import { auth } from "./firebase";

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
	const [currentUser, setCurrentUser] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged((user) => {
			setCurrentUser(user);
			setLoading(false);
		});

		return () => unsubscribe();
	}, []);

	const logout = async () => {
		await auth.signOut();
		setCurrentUser(null);
		localStorage.removeItem("snDataKey"); // Clear any additional data
	};

	return (
		<UserContext.Provider value={{ currentUser, loading, logout }}>
			{children}
		</UserContext.Provider>
	);
};
