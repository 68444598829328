import React from "react";
import Layout from "../components/Layout";
import { Link } from "react-router-dom";

export default function PageNotFound() {
	let content = <Content />;

	return <Layout content={content} />;
}

const Content = () => {
	return (
		<section className="bleezy-about-page section_50 minHeightPage">
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<h1 className="very-big">404</h1>
						<h1 className="page-title">Page Not Found</h1>
						<p>It looks like you may have taken a wrong turn.</p>
						<p>Don't worry, just go back to home page.</p>
						<Link to="/" className="btn-back">
							Back to home
						</Link>
					</div>
				</div>
			</div>
		</section>
	);
};
