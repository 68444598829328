export type Post = {
	id: string;
	title: string;
	content?: string;
	authorId?: string;
	createdAt?: Date;
	updatedAt?: Date;
};

export const PostData: Post[] = [
	{
		id: "POST_1",
		title: "Test post",
	},
	{
		id: "POST_2",
		title: "Another post",
	},
	{
		id: "POST_3",
		title: "Yet another post",
	},
];
