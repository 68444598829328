import { useEffect, useState } from "react";
import Modal from "react-modal";

import Layout from "../../../components/Layout";
import { SignInCheck } from "../../../components/SignInCheck";
import { Block } from "../../../schema/Block";
import { BlockTable } from "../components/BlockTable";
import { BlockModal } from "../components/BlockModal";
import { useBlocks } from "../hooks/useBlocks";
import { Link } from "react-router-dom";

export default function BlockHome() {
	const { blocks, fetchBlocks } = useBlocks();

	useEffect(() => {
		fetchBlocks();
	}, []);

	const renderBlocks = blocks.map((block, key) => (
		<div className="col-md-4 col-sm-4 space-below" key={key}>
			<div className="single-promo">
				<div className="promo-image">
					<Link to="courses?id=door-supervisor">
						<img src={block.imageUrl} alt={block.name} />
					</Link>
				</div>
				<div className="promo-text">
					<h2>
						<Link to="courses?id=door-supervisor">
							{block.name}
						</Link>
					</h2>
				</div>
			</div>
		</div>
	));

	return <div className="row">{renderBlocks}</div>;
}
