import { useState } from "react";
import {
	collection,
	query,
	getDocs,
	updateDoc,
	doc,
	addDoc,
} from "firebase/firestore";

import { db } from "../../../utils/firebase";

function CreateForm({ onCloseClick, onCreateComplete }) {
	const COLLECTION_NAME = "contacts";

	const [contact, setContact] = useState({
		city: "",
		address: "",
		phone: "",
		email: "",
	});
	const [errors, setErrors] = useState("");
	const [formIsProcessing, setFormIsProcessing] = useState(false);

	const handleCloseClick = () => {
		onCloseClick();
	};

	const handleCityChange = (e) => {
		const val = e.target.value;
		setContact((prev) => ({
			...prev,
			city: val,
		}));
		setErrors("");
	};

	const handleAddressChange = (e) => {
		const val = e.target.value;
		setContact((prev) => ({
			...prev,
			address: val,
		}));
		setErrors("");
	};

	const handlePhoneChange = (e) => {
		const val = e.target.value;
		setContact((prev) => ({
			...prev,
			phone: val,
		}));
		setErrors("");
	};

	const handleEmailChange = (e) => {
		const val = e.target.value;
		setContact((prev) => ({
			...prev,
			email: val,
		}));
		setErrors("");
	};

	const saveContact = async (data) => {
		try {
			const docRef = await addDoc(collection(db, COLLECTION_NAME), data);
			onCreateComplete(contact);
		} catch (error) {
			console.error("Error adding document: ", error);
		}
	};

	const handleSubmit = () => {
		setFormIsProcessing(true);
		if (
			contact.city.length <= 0 ||
			contact.address.length <= 0 ||
			contact.phone.length <= 0 ||
			contact.email.length <= 0
		) {
			setErrors("All fields are required");
			setFormIsProcessing(false);
			return;
		}

		const data = {
			city: contact.city,
			address: contact.address,
			phone: contact.phone,
			email: contact.email,
		};

		saveContact(data);

		setFormIsProcessing(false);
	};

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				width: 400,
				gap: 10,
				position: "relative",
			}}
		>
			{errors.length > 0 && (
				<div
					style={{
						color: "red",
					}}
				>
					<p>{errors}</p>
				</div>
			)}

			<input
				type="text"
				placeholder="City"
				value={contact.city}
				onChange={handleCityChange}
			/>
			<textarea
				type="text"
				placeholder="Address"
				value={contact.address}
				onChange={handleAddressChange}
			/>
			<input
				type="text"
				placeholder="phone"
				value={contact.phone}
				onChange={handlePhoneChange}
			/>
			<input
				type="text"
				placeholder="Email"
				value={contact.email}
				onChange={handleEmailChange}
			/>
			<button onClick={handleSubmit} disabled={formIsProcessing}>
				Create
			</button>
			<button
				onClick={handleCloseClick}
				style={{
					backgroundColor: "#f26723",
					color: "white",
					border: "none",
				}}
			>
				Cancel
			</button>
		</div>
	);
}

export default CreateForm;
