import { useState } from "react";

export const CourseAddForm = ({ handleFormCreate, closeAddForm }) => {
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [dates, setDates] = useState("");
	const [timing, setTiming] = useState("");
	const [published, setPublished] = useState(false);
	const [formIsProcessing, setFormIsProcessing] = useState(false);

	const handleTitleChange = (e) => {
		setTitle(e.target.value);
	};

	const handleDescriptionChange = (e) => {
		setDescription(e.target.value);
	};

	const handleDatesChange = (e) => {
		setDates(e.target.value);
	};

	const handleTimingChange = (e) => {
		setTiming(e.target.value);
	};

	const handleSubmit = () => {
		const data = {
			title: title,
			description: description,
			timing: timing,
			published: published,
		};
		handleFormCreate(data);
	};

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				width: 400,
				gap: 10,
				position: "relative",
			}}
		>
			<textarea
				type="text"
				value={title}
				onChange={handleTitleChange}
				rows={5}
				style={styles.input}
				placeholder="Enter title here..."
			/>
			<textarea
				type="text"
				value={description}
				onChange={handleDescriptionChange}
				style={styles.input}
				placeholder="Enter description here..."
			/>
			<textarea
				type="text"
				value={dates}
				onChange={handleDatesChange}
				style={styles.input}
				placeholder="Enter dates here..."
			/>
			<textarea
				type="text"
				value={timing}
				onChange={handleTimingChange}
				style={styles.input}
				placeholder="Enter timing here..."
			/>
			<div className="input-checkbox">
				<input
					type="checkbox"
					id="published"
					checked={published}
					onChange={() => {
						setPublished(!published);
					}}
				/>
				<label htmlFor="published">Published</label>
			</div>
			<button
				onClick={() => {
					setFormIsProcessing(true);
					handleSubmit();
				}}
				disabled={formIsProcessing}
			>
				Add
			</button>
			<button
				onClick={closeAddForm}
				style={{
					backgroundColor: "#f26723",
					color: "white",
					border: "none",
				}}
			>
				Cancel
			</button>
		</div>
	);
};

const styles = {
	input: {
		padding: 4,
		border: "1px solid #ccc",
	},
};
