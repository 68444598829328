// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyBgT3NJbEcZqHsIlMtdOuwp3_yvzLhZYFY",
	authDomain: "sntraining-cd68a.firebaseapp.com",
	projectId: "sntraining-cd68a",
	storageBucket: "sntraining-cd68a.appspot.com",
	messagingSenderId: "230167994174",
	appId: "1:230167994174:web:03c7bdeec4c9f4d0c0566f",
	measurementId: "G-24XVVQ3ZT6",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };
