import React, { useState } from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { Link } from "react-router-dom";

import logo from "../img/logo.png";
import { useUser } from "../utils/UserContext";

export default function Layout({ content }) {
	const { currentUser, logout } = useUser();
	const [showMenu, setShowMenu] = React.useState(false);

	const handleAccept = () => {
		Cookies.set("nama-cookie-accepted", true, { expires: 365 });
	};

	const handleShowMenu = (event) => {
		event.preventDefault();
		setShowMenu(!showMenu);
	};

	const handleLogout = () => {
		logout();
	};

	return (
		<div id="app-layout">
			<header className="bleezy-header-area">
				<div className="header-right-overlay"></div>

				<div className="container">
					<div className="row">
						<div className="col-md-3">
							<div className="site-logo">
								<a href="/">
									<img src={logo} alt="site logo" />
								</a>
							</div>
						</div>
						<div className="col-md-9">
							<div className="header-right">
								<div className="header-right-top">
									<div className="row">
										<div className="col-md-4">
											<div className="single-top-right">
												<p>
													Call Us:{" "}
													<a href="tel:+4407787565432">
														(+44) 07787 565432
													</a>
												</p>
												{currentUser && (
													<button
														onClick={handleLogout}
														style={{
															color: "black",
															border: "none",
															marginLeft: 20,
														}}
													>
														Logout
													</button>
												)}
											</div>
										</div>
										<div className="col-md-4">
											<div className="single-top-right"></div>
										</div>
										<div className="col-md-4">
											<div className="single-top-right"></div>
										</div>
									</div>
								</div>

								<div className="menu-container">
									<div className="row">
										<div className="col-md-11 col-sm-11">
											<div className="bleezy-responsive-menu">
												<div className="slicknav_menu">
													<a
														href="#"
														aria-haspopup="true"
														role="button"
														tabIndex="0"
														className="slicknav_btn slicknav_collapsed"
														onClick={handleShowMenu}
													>
														<span className="slicknav_menutxt">
															MENU
														</span>
														<span className="slicknav_icon">
															<span className="slicknav_icon-bar"></span>
															<span className="slicknav_icon-bar"></span>
															<span className="slicknav_icon-bar"></span>
														</span>
													</a>
													{showMenu && (
														<ul
															className="slicknav_nav slicknav_hidden"
															aria-hidden="true"
															role="menu"
														>
															<li>
																<Link to="/">
																	home
																</Link>
															</li>
															<li>
																<Link to="/courses">
																	courses
																</Link>
															</li>
															<li>
																<Link to="/about-us">
																	about us
																</Link>
															</li>
															<li>
																<Link to="/blog">
																	blog
																</Link>
															</li>
															<li>
																<Link to="/contact">
																	contact
																</Link>
															</li>
														</ul>
													)}
												</div>
											</div>
											<div className="mainmenu">
												<nav>
													<ul id="bleezy_navigation">
														<li>
															<Link to="/">
																home
															</Link>
														</li>
														<li>
															<Link to="/courses">
																courses
															</Link>
														</li>
														<li>
															<Link to="/about-us">
																about us
															</Link>
														</li>
														<li>
															<Link to="/blog">
																blog
															</Link>
														</li>
														<li>
															<Link to="/contact">
																contact
															</Link>
														</li>
													</ul>
												</nav>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				{currentUser && (
					<div className="container dash-link">
						<div className="row">
							<div className="col-md-12">
								<ul>
									<li>
										<Link to="/dashboard">Dashboard</Link>
									</li>
								</ul>
							</div>
						</div>
					</div>
				)}

				{content}
			</header>

			<footer className="bleezy-footer-area">
				<div className="footer-top-area section_50">
					<div className="container">
						<div className="row">
							<div className="col-md-3 col-sm-3">
								<div className="single-footer-widget">
									<h3>main links</h3>
									<ul>
										<li>
											<a href="courses">Courses</a>
										</li>
										<li>
											<a href="about-us">About us</a>
										</li>
										<li>
											<a href="contact">Contact Us</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="copyright-area">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<div className="copyright-right">
									<p>
										&copy; Copyright{" "}
										<a href="#">
											S&N Training and Services
										</a>{" "}
										2024. All rights reserved. Site designed
										by <a href="#">Nirmal Limbu</a>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<CookieConsent
					location="bottom"
					buttonText="Accept"
					cookieName="myAwesomeCookieName2"
					style={{ background: "#2B373B" }}
					buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
					expires={150}
					onAccept={handleAccept}
				>
					This website uses cookies to enhance the user experience.{" "}
				</CookieConsent>
			</footer>
		</div>
	);
}
