import {
	createColumnHelper,
	flexRender,
	getCoreRowModel,
	useReactTable,
} from "@tanstack/react-table";
import { Block } from "@schema/Block";

const columnHelper = createColumnHelper<Block>();
const columns = [
	columnHelper.accessor((row) => row.name, {
		id: "name",
		cell: (info) => <i>{info.getValue()}</i>,
		header: () => <span>Name</span>,
		footer: (info) => info.column.id,
	}),
	columnHelper.accessor((row) => row.category, {
		id: "category",
		cell: (info) => <i>{info.getValue()}</i>,
		header: () => <span>Category</span>,
		footer: (info) => info.column.id,
	}),
	columnHelper.accessor("id", {
		cell: (info) => info.getValue(),
		header: () => <span>ID</span>,
		footer: (info) => info.column.id,
	}),
	columnHelper.accessor((row) => row.imageUrl, {
		id: "imageUrl",
		cell: (info) => {
			return <img src={info.getValue()} className="thumbnail" />;
		},
		header: () => <span>Image</span>,
		footer: (info) => info.column.id,
	}),
];

export const BlockTable = ({ data, onItemClick }: Props) => {
	const table = useReactTable({
		data,
		columns,
		getCoreRowModel: getCoreRowModel(),
	});

	const handleItemClick = (row) => {
		onItemClick(row.original);
	};

	return data.length ? (
		<div className="table-wrapper">
			<table className="table">
				<thead>
					{table.getHeaderGroups().map((headerGroup) => (
						<tr key={headerGroup.id}>
							{headerGroup.headers.map((header) => (
								<th key={header.id}>
									{header.isPlaceholder
										? null
										: flexRender(
												header.column.columnDef.header,
												header.getContext()
										  )}
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody>
					{table.getRowModel().rows.map((row) => (
						<tr key={row.id} onClick={() => handleItemClick(row)}>
							{row.getVisibleCells().map((cell) => (
								<td key={cell.id}>
									{flexRender(
										cell.column.columnDef.cell,
										cell.getContext()
									)}
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	) : (
		<div>No blocks created yet.</div>
	);
};

type Props = {
	data: Block[];
	onItemClick: (block: Block) => void;
};
