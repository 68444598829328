import { useEffect } from "react";

import Layout from "../components/Layout";

import promoImg from "../img/thumb-1.png";
import promoImg2 from "../img/thumb-2.png";
import promoImg3 from "../img/thumb-3.png";
import { Link } from "react-router-dom";
import BlockHome from "./blocks/blocks/BlockHome";
import { Offer } from "./offers/Offer";

export default function HomePage({ pageTitle }) {
	let content = <Content />;

	useEffect(() => {
		document.title = pageTitle;
	}, [pageTitle]);

	return <Layout content={content} />;
}

const Content = () => {
	return (
		<>
			<Offer />
			<section className="bleezy-slider-area">
				<div className="bleezy-slide">
					<div className="bleezy-main-slide slide-item-1">
						<div className="bleezy-main-caption">
							<div className="bleezy-caption-cell">
								<div className="container">
									<div className="row">
										<div className="col-md-12">
											<div className="slider-text">
												<h2>
													Starting your career in{" "}
													<span>security</span>
												</h2>
												<p>
													Whether you are just
													starting your career in
													security or looking to
													advance your skills, our
													training courses can help
													you achieve your goals
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="bleezy-promo-area section_100">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="promo-heading">
								<h2>
									We provide effective security courses
									<span>with flexible timing</span>
								</h2>
							</div>
						</div>
					</div>

					<BlockHome />
				</div>
			</section>

			<section className="bleezy-service-area section_t_100 section_b_70">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="site-heading">
								<h3>What We Offer</h3>
								<h2>
									<a
										className="link-courses"
										href="./courses#course-dates"
									>
										our courses
									</a>
								</h2>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-4 col-sm-4">
							<div className="single-service">
								<div className="service-icon">
									<i className="flaticon-house-security"></i>
								</div>
								<h3>
									<a href="./courses#course-dates">
										Door Supervisor
									</a>
								</h3>
								<p>Door Supervisor full course and top up.</p>
							</div>
						</div>
						<div className="col-md-4 col-sm-4">
							<div className="single-service">
								<div className="service-icon">
									<i className="flaticon-security-camera"></i>
								</div>
								<h3>
									<a href="./courses#course-dates">
										Security Officer
									</a>
								</h3>
								<p>
									Security Officer/Guard full course and top
									up
								</p>
							</div>
						</div>
						<div className="col-md-4 col-sm-4">
							<div className="single-service">
								<div className="service-icon">
									<i className="flaticon-locked-internet-security-padlock"></i>
								</div>
								<h3>
									<a href="./courses#course-dates">
										CCTV Operator
									</a>
								</h3>
								<p>CCTV Operator /Full course and top up</p>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-4 col-sm-4">
							<div className="single-service">
								<div className="service-icon">
									<i className="flaticon-computer"></i>
								</div>
								<h3>
									<a href="./courses#course-dates">
										First Aid (FAW)
									</a>
								</h3>
								<p>
									First Aid at Work (FAW) and refresher course
									- (RQF)
								</p>
							</div>
						</div>
						<div className="col-md-4 col-sm-4">
							<div className="single-service">
								<div className="service-icon">
									<i className="flaticon-policeman"></i>
								</div>
								<h3>
									<a href="./courses#course-dates">
										First Aid (EFAW)
									</a>
								</h3>
								<p>Emergency First Aid at Work (EFAW) - RQF</p>
							</div>
						</div>
						<div className="col-md-4 col-sm-4">
							<div className="single-service">
								<div className="service-icon">
									<i className="flaticon-fingerprint"></i>
								</div>
								<h3>
									<a href="./courses#course-dates">
										Paediatric First Aid
									</a>
								</h3>
								<p>Paediatric First Aid</p>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};
