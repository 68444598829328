import Layout from "../../components/Layout";
import { SignInCheck } from "../../components/SignInCheck";
import { PostData } from "../../schema/Post";
import { PostTable } from "./components/PostTable";

export default function PostsPage() {
	let content = <Content />;

	return <Layout content={content} />;
}

const Content = () => {
	return (
		<>
			<section className="bleezy-about-page section_50">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<h2>Manage posts</h2>
						</div>

						<div className="col-md-12">
							<div className="actions">
								<div className="action-item">
									<button>Create</button>
								</div>
							</div>
						</div>

						<div className="col-md-12">
							<PostTable data={PostData} />
						</div>
					</div>
				</div>
			</section>
			<SignInCheck />
		</>
	);
};
