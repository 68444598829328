import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import {
	getAuth,
	revokeAccessToken,
	signInWithCustomToken,
	signInWithEmailAndPassword,
} from "firebase/auth";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: process.env.REACT_APP_FB_API_KEY,
	authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FB_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FB_APP_ID,
	measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const auth = getAuth(app);
// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);

export const logInWithEmailAndPassword = async (email, password) => {
	let result = "";
	try {
		const res = await signInWithEmailAndPassword(auth, email, password);
		const user = res.user;

		if (user) {
			let userId = user.id;
			if (userId == undefined) userId = user.reloadUserInfo.localId;
			localStorage.setItem("userId", userId);
			localStorage.setItem("userEmail", user.email);
			localStorage.setItem("accessToken", user.accessToken);

			result = user;
		}
	} catch (err) {
		result = err.message;
		switch (err.message) {
			case "Authentication Error":
				result = "Email or password did not match.";
				break;
			default:
				result = err.message;
				break;
		}
	}

	return result;
};

export async function verifyToken(accessToken) {
	try {
		await signInWithCustomToken(auth, accessToken);
		console.log("Token is valid");
	} catch (error) {
		console.error("Error verifying token:", error);
	}
}

export async function expireAccessToken(accessToken) {
	try {
		await revokeAccessToken(auth, accessToken);
		console.log("access token was revoked");
	} catch (error) {
		console.log("could not revoke the access token");
	}
}
