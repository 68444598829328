import React, { useEffect, useState } from "react";
import {
	collection,
	query,
	where,
	orderBy,
	getDocs,
	limit,
} from "firebase/firestore";
import { db } from "../api/firebase";

import Layout from "../components/Layout";
import { formatDate } from "../utils/dates";

export default function BlogPage({ title }) {
	const [posts, setPosts] = useState([]);

	let content = <Content posts={posts} />;

	const getPosts = async () => {
		const q = query(
			collection(db, "posts"),
			where("published", "==", true),
			orderBy("date", "desc"),
			limit(10)
		);
		const querySnapshot = await getDocs(q);
		let list = [];

		querySnapshot.forEach((doc) => {
			let item = {
				id: doc.id,
				...doc.data(),
			};
			item.date = formatDate(item.date);
			list.push(item);
		});

		setPosts(list);
		console.log("posts", list);
	};

	useEffect(() => {
		setTimeout(() => {
			getPosts();
		}, 1200);
	}, []);

	useEffect(() => {
		document.title = title; // Set the document title dynamically
	}, [title]);

	return <Layout content={content} />;
}

const Content = ({ posts }) => {
	const renderedItems = posts.map((item, index) => {
		return (
			<div className="container blog-item" key={index}>
				<div className="row">
					<div className="col-md-12">
						<div className="blog-page-left">
							<div className="row">
								<div className="col-md-4">
									<a href={`./blog/${item.id}`}>
										<img
											src={item.image}
											alt={item.title}
										/>
									</a>
								</div>
								<div className="col-md-8 blog-item-right">
									<div className="wrapper">
										<h3>
											<a href={`./blog/${item.id}`}>
												{item.title}
											</a>
										</h3>
										<p className="blog-item-date">
											{item.date}
										</p>
										<div
											dangerouslySetInnerHTML={{
												__html: item.summary,
											}}
										/>
										<a href={`./blog/${item.id}`}>
											read more
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	});

	return (
		<>
			<section className="bleezy-breadcromb-area">
				<div className="breadcromb-top section_50">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<div className="breadcromb-top-text">
									<h2>Articles</h2>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="breadcromb-bottom">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<div className="breadcromb-bottom-text">
									<ul>
										<li>
											<a href="index.html">home</a>
										</li>
										<li>
											<a href="#">
												<i className="fa fa-long-arrow-right"></i>
											</a>
										</li>
										<li>blog</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="bleezy-blog-page-area section_100">
				{posts.length === 0 ? (
					<section className="bleezy-blog-page-area section_100">
						<div className="container">
							<div className="row">
								<div
									className="col-md-12"
									style={{
										display: "flex",
										justifyContent: "center",
										minHeight: 200,
									}}
								>
									<div className="lds-ripple ">
										<div></div>
										<div></div>
									</div>
								</div>
							</div>
						</div>
					</section>
				) : (
					<>{renderedItems}</>
				)}
			</section>
		</>
	);
};
