import { useEffect, useState } from "react";
import { MdCalendarMonth, MdOutlineClose } from "react-icons/md";
import { GrAnnounce } from "react-icons/gr";
import { Link } from "react-router-dom";

export const Offer = () => {
	const [offer, setOffer] = useState<OfferEntity | null>(null);
	const [show, setShow] = useState<boolean>(true);

	useEffect(() => {
		const data: OfferEntity = {
			id: 1,
			title: "Door Supervisor Course",
			description:
				"We are running a course soon on 13-19 May 2024 in SGNCAK Hall, Ashford , Kent, TN24 8RY. Book now!",
			type: OfferType.Promotion,
			price: 100,
		};
		setOffer(data);
	}, []);

	return (
		<div>
			{offer && show ? (
				<div className="offer-wrapper bg">
					<div className="offer-container">
						<GrAnnounce size={75} />
						<h1>{offer.title}</h1>
						<p>{offer.description}</p>
						<div className="action-wrapper">
							<MdCalendarMonth size={40} />
							<div className="button">
								<Link to="/courses">View Dates</Link>
							</div>
						</div>
						<div
							className="offer-close"
							onClick={() => setShow(false)}
						>
							<MdOutlineClose size={22} />
						</div>
					</div>
				</div>
			) : (
				<></>
			)}
		</div>
	);
};

enum OfferType {
	Discount = "Discount",
	Sale = "Sale",
	Promotion = "Promotion",
}

type OfferEntity = {
	id: number;
	title: string;
	description: string;
	type: OfferType;
	price: number;
};
