import { useEffect, useState } from "react";
import Modal from "react-modal";

import Layout from "../../components/Layout";
import { SignInCheck } from "../../components/SignInCheck";
import { Block } from "../../schema/Block";
import { BlockTable } from "./components/BlockTable";
import { BlockModal } from "./components/BlockModal";
import { useBlocks } from "./hooks/useBlocks";

export default function BlocksPage() {
	Modal.setAppElement("#root");

	const { blocks, createBlock, updateBlock, fetchBlocks, loading } =
		useBlocks();

	const [isOpen, setIsOpen] = useState(false);
	const [block, setBlock] = useState<Block | null>(null);

	const handleCreateClick = () => {
		setBlock(null);
		setIsOpen(true);
	};

	const handleBlockClick = (block: Block) => {
		setBlock(block);
		setIsOpen(true);
	};

	const handleSubmit = async (block: Block) => {
		if (block.id === "") {
			await createBlock(block);
			setIsOpen(false);
			fetchBlocks();
		} else if (block.id.length) {
			await updateBlock(block);
			setIsOpen(false);
			fetchBlocks();
		}
	};

	let content = (
		<Content
			blocks={blocks}
			loading={loading}
			onCreateClick={handleCreateClick}
			onBlockClick={handleBlockClick}
		/>
	);

	useEffect(() => {
		fetchBlocks();
	}, []);

	return (
		<>
			<Layout content={content} />
			<BlockModal
				block={block}
				isOpen={isOpen}
				onSubmit={(block: Block) => handleSubmit(block)}
				afterOpenModal={() => {
					// do something
				}}
				closeModal={() => {
					setIsOpen(false);
				}}
			/>
		</>
	);
}

const Content = ({ blocks, loading, onCreateClick, onBlockClick }) => {
	const handleBlockClick = (block) => {
		onBlockClick(block);
	};

	return (
		<>
			<section className="bleezy-about-page section_50">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<h2>Manage blocks</h2>
						</div>

						<div className="col-md-12">
							<div className="actions">
								<div className="action-item">
									<button onClick={onCreateClick}>
										Create
									</button>
								</div>
							</div>
						</div>

						<div className="col-md-12">
							{loading ? (
								<div
									className="col-md-12"
									style={{
										display: "flex",
										justifyContent: "center",
										minHeight: 200,
										marginTop: 50,
									}}
								>
									<div className="lds-ripple ">
										<div></div>
										<div></div>
									</div>
								</div>
							) : (
								<BlockTable
									data={blocks}
									onItemClick={(block) =>
										handleBlockClick(block)
									}
								/>
							)}
						</div>
					</div>
				</div>
			</section>

			<SignInCheck />
		</>
	);
};
