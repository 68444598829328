import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";

import BlogPage from "./pages/BlogPage";
import ContactPage from "./pages/contacts/ContactPage";
import SingleBlogPage from "./pages/SingleBlogPage";
import LoginPage from "./pages/LoginPage";

import "./scss/main.scss";
import PostsPage from "./pages/posts/PostsPage";
import BlocksPage from "./pages/blocks/BlocksPage";
import PageNotFound from "./pages/PageNotFound";
import Dashboard from "./pages/dashboard/DashboardPage";
import CoursesPage from "./pages/courses/CoursesPage";
import { UserProvider } from "./utils/UserContext";

const SITE_NAME = "S & N Training and Services";

function App() {
	return (
		<UserProvider>
			<Router>
				<Routes>
					<Route
						path="/"
						element={<HomePage pageTitle={`${SITE_NAME}`} />}
					/>

					<Route
						path="/about-us"
						element={
							<AboutPage title={`About Us - ${SITE_NAME}`} />
						}
					/>

					<Route
						path="/courses"
						element={
							<CoursesPage pageTitle={`Courses - ${SITE_NAME}`} />
						}
					/>

					<Route
						path="/blog"
						element={<BlogPage title={`Blog - ${SITE_NAME}`} />}
					/>

					<Route
						path="/blog/:slug"
						element={<SingleBlogPage pageTitle={`${SITE_NAME}`} />}
					/>

					<Route
						path="/contact"
						element={
							<ContactPage pageTitle={`Contact - ${SITE_NAME}`} />
						}
					/>

					<Route path="/admin-login-london" element={<LoginPage />} />

					<Route
						path="/dashboard"
						element={
							<Dashboard title={`Dashboard - ${SITE_NAME}`} />
						}
					/>

					<Route path="/posts" element={<PostsPage />} />

					<Route path="/blocks" element={<BlocksPage />} />

					<Route path="/*" element={<PageNotFound />} />
				</Routes>
			</Router>
		</UserProvider>
	);
}

export default App;
