import { useState } from "react";
import {
	collection,
	query,
	where,
	getDocs,
	updateDoc,
	doc,
	addDoc,
} from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";

import { Block } from "@schema/Block";
import { db } from "../../../api/firebase";

// fetch blocks using this hook with some state
export const useBlocks = () => {
	const COLLECTION_NAME = "blocks";
	const [blocks, setBlocks] = useState<Block[]>([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const fetchBlocks = async () => {
		setLoading(true);
		const q = query(collection(db, COLLECTION_NAME));
		const querySnapshot = await getDocs(q);
		let list = [];

		querySnapshot.forEach((doc) => {
			let item = {
				docId: doc.id,
				...doc.data(),
			};
			list.push(item);
		});

		setLoading(false);
		setBlocks(list);
	};

	const createBlock = async (block: Block) => {
		try {
			block.id = uuidv4();
			const docRef = await addDoc(collection(db, COLLECTION_NAME), block);
		} catch (error) {
			console.error("Error adding document: ", error);
		}
	};

	const updateBlock = async (block: Block) => {
		try {
			const docRef = doc(db, COLLECTION_NAME, block.docId);
			await updateDoc(docRef, block);
		} catch (error) {}
	};

	return {
		blocks,
		loading,
		error,
		fetchBlocks,
		createBlock,
		updateBlock,
	};
};
