import { useEffect, useState } from "react";
import {
	collection,
	query,
	where,
	getDocs,
	updateDoc,
	doc,
	addDoc,
} from "firebase/firestore";
import { MdEditSquare } from "react-icons/md";

import Modal from "react-modal";
import { db } from "../../api/firebase";

import Layout from "../../components/Layout";
import { CourseAddForm } from "../../forms/CourseAddForm";
import { useUser } from "../../utils/UserContext";

// const image = require("../../img/980x551.png").default;

const customStyles = {
	overlay: {
		backgroundColor: "rgba(0,0,0,0.2)",
	},
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
	},
};

export default function CoursesPage() {
	Modal.setAppElement("#root");

	const waitTime = 1200;

	const { currentUser } = useUser();

	const [courses, setCourses] = useState([]);
	const [modalIsOpen, setIsOpen] = useState(false);
	const [addFormIsOpen, setAddFormIsOpen] = useState(false);

	const [courseId, setCourseId] = useState("");
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [timing, setTiming] = useState("");
	const [dates, setDates] = useState("");
	const [published, setPublished] = useState(true);
	const [formIsProcessing, setFormIsProcessing] = useState(false);

	const [accessToken, setAccessToken] = useState(
		localStorage.getItem("accessToken")
			? localStorage.getItem("accessToken")
			: null
	);

	const getCourses = async () => {
		const q = query(
			collection(db, "courses"),
			where("published", "==", true)
		);
		const querySnapshot = await getDocs(q);
		let list = [];

		querySnapshot.forEach((doc) => {
			let item = {
				id: doc.id,
				...doc.data(),
			};
			list.push(item);
		});

		console.log("list", list);

		setCourses(list);
	};

	const handleClick = (course) => {
		console.log("course", course);
		setCourseId(course.id);
		setTitle(course.title);
		setDescription(course.description);
		setTiming(course.timing);
		setDates(course.dates);
		setIsOpen(true);
	};

	const handleClickAddCourse = () => {
		setAddFormIsOpen(true);
	};

	const closeAddForm = () => {
		setAddFormIsOpen(false);
	};

	const afterOpenModal = () => {
		// references are now sync'd and can be accessed.
		// subtitle.style.color = '#f00';
	};

	const closeModal = () => {
		setIsOpen(false);
	};

	const handleTitleChange = (e) => {
		setTitle(e.target.value);
	};

	const handleTimingChange = (e) => {
		setTiming(e.target.value);
	};

	const handleDescriptionChange = (e) => {
		setDescription(e.target.value);
	};

	const handleDatesChange = (e) => {
		setDates(e.target.value);
	};

	const createDocument = async (collectionName, data) => {
		try {
			const docRef = await addDoc(collection(db, collectionName), data);
			console.log("Document written with ID: ", docRef.id);
		} catch (error) {
			console.error("Error adding document: ", error);
		}
	};

	const handleFormCreate = (data) => {
		if (formIsProcessing) return;
		createDocument("courses", data);
		closeAddForm();
		setCourses([]);
		setTimeout(async () => {
			await getCourses();
			setFormIsProcessing(false);
		}, waitTime);
	};

	const updateDocument = async (collectionName, docId, newData) => {
		try {
			const docRef = doc(db, collectionName, docId);
			await updateDoc(docRef, newData);
			console.log("Document successfully updated!");
		} catch (error) {
			console.error("Error updating document: ", error);
		}
	};

	const handleCourseUpdate = async () => {
		if (formIsProcessing) return;

		setFormIsProcessing(true);
		const data = {
			id: courseId,
			title: title,
			description: description,
			dates: dates,
			timing: timing,
			published: published,
		};
		console.log("data", data);
		await updateDocument("courses", courseId, data);
		closeModal();
		setCourses([]);
		setTimeout(async () => {
			await getCourses();
			setFormIsProcessing(false);
		}, waitTime);
	};

	useEffect(() => {
		setTimeout(() => {
			getCourses();
		}, waitTime);
	}, []);

	let content = (
		<Content
			courses={courses}
			currentUser={currentUser}
			handleClick={handleClick}
			handleClickAddCourse={handleClickAddCourse}
		/>
	);

	return (
		<>
			<Layout content={content} />

			<Modal
				isOpen={addFormIsOpen}
				style={customStyles}
				contentLabel="Add Course Modal"
			>
				<CourseAddForm
					closeAddForm={closeAddForm}
					handleFormCreate={handleFormCreate}
				/>
			</Modal>

			<Modal
				isOpen={modalIsOpen}
				onAfterOpen={afterOpenModal}
				onRequestClose={closeModal}
				style={customStyles}
				contentLabel="Update Course Modal"
			>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						width: 400,
						gap: 10,
						position: "relative",
					}}
				>
					<textarea
						value={title}
						onChange={handleTitleChange}
						rows={5}
						style={styles.input}
						placeholder="Enter title here..."
					/>
					<textarea
						value={description}
						onChange={handleDescriptionChange}
						style={styles.input}
						placeholder="Enter description here..."
					/>
					<textarea
						value={dates}
						onChange={handleDatesChange}
						style={styles.input}
						placeholder="Enter dates here..."
					/>
					<textarea
						value={timing}
						onChange={handleTimingChange}
						style={styles.input}
						placeholder="Enter timing here..."
					/>
					<div className="input-checkbox">
						<input
							type="checkbox"
							id="published"
							checked={published}
							onChange={() => {
								setPublished(!published);
							}}
						/>
						<label htmlFor="published">Published</label>
					</div>
					<button
						onClick={handleCourseUpdate}
						disabled={formIsProcessing}
					>
						Update
					</button>
					<button
						onClick={closeModal}
						style={{
							backgroundColor: "#f26723",
							color: "white",
							border: "none",
						}}
					>
						Cancel
					</button>
				</div>
			</Modal>
		</>
	);
}

const CourseItem = ({ course, currentUser, handleClick }) => {
	const [showDescription, setShowDescription] = useState(false);
	const [showDates, setShowDates] = useState(false);
	const [showTiming, setShowTiming] = useState(false);

	const handleShowDescription = (event) => {
		event.preventDefault();
		setShowDescription(!showDescription);
	};
	const handleShowDates = (event) => {
		event.preventDefault();
		setShowDates(!showDates);
	};
	const handleShowTiming = (event) => {
		event.preventDefault();
		setShowTiming(!showTiming);
	};

	const dates = course?.dates?.split("\n").map((item, key) => {
		return (
			<li key={key}>
				{item}
				<br />
			</li>
		);
	});

	const timing = course.timing.split("\n").map((item, key) => {
		return (
			<li key={key}>
				{item}
				<br />
			</li>
		);
	});

	return (
		<div className="course-item">
			<div className="course-item-title">
				<h3>{course.title}</h3>
				{currentUser && (
					<MdEditSquare
						size={22}
						style={{ cursor: "pointer" }}
						onClick={() => handleClick(course)}
					/>
				)}
			</div>
			<div className="panel-group accordion" id="accordion">
				<div className="panel panel-default">
					<div className="panel-heading">
						<h4 className="panel-title finance-panel-title">
							<a
								className="collapsed"
								data-toggle="collapse"
								data-parent="#accordion"
								href="#"
								aria-expanded="false"
								onClick={handleShowDescription}
							>
								<i className="switch fa fa-plus"></i>
								Description
							</a>
						</h4>
					</div>
					<div
						className={
							showDescription
								? "panel-collapse"
								: "panel-collapse collapse"
						}
						id="collapse02"
					>
						<div className="panel-body">
							<p>{course.description}</p>
						</div>
					</div>
				</div>
				<div className="panel panel-default">
					<div className="panel-heading">
						<h4 className="panel-title finance-panel-title">
							<a
								className="collapsed"
								data-toggle="collapse"
								data-parent="#accordion"
								href="#collapse03"
								aria-expanded="false"
								onClick={handleShowDates}
							>
								<i className="switch fa fa-plus"></i>
								Dates
							</a>
						</h4>
					</div>
					<div
						className={
							showDates
								? "panel-collapse"
								: "panel-collapse collapse"
						}
						id="collapse03"
					>
						<div className="panel-body">
							<ul>{dates}</ul>
						</div>
					</div>
				</div>
				<div className="panel panel-default">
					<div className="panel-heading">
						<h4 className="panel-title finance-panel-title">
							<a
								className="collapsed"
								data-toggle="collapse"
								data-parent="#accordion"
								href="#collapse04"
								aria-expanded="false"
								onClick={handleShowTiming}
							>
								<i className="switch fa fa-plus"></i>
								Timing
							</a>
						</h4>
					</div>
					<div
						className={
							showTiming
								? "panel-collapse"
								: "panel-collapse collapse"
						}
						id="collapse04"
					>
						<div className="panel-body">
							<p>{timing}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const Content = ({
	courses,
	currentUser,
	handleClick,
	handleClickAddCourse,
}) => {
	let renderedItems = courses.map((course) => {
		return (
			<CourseItem
				course={course}
				key={course.id}
				currentUser={currentUser}
				handleClick={handleClick}
			/>
		);
	});

	const imageUrl =
		"https://firebasestorage.googleapis.com/v0/b/sntraining-cd68a.appspot.com/o/980x551.png?alt=media&token=c20c7df8-356c-4b87-a89f-140d2ec2ffb2";

	return (
		<>
			<section className="bleezy-breadcromb-area">
				<div className="breadcromb-top section_50">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<div className="breadcromb-top-text">
									<h2>Our Courses</h2>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="breadcromb-bottom">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<div className="breadcromb-bottom-text">
									<ul>
										<li>
											<a href="index.html">home</a>
										</li>
										<li>
											<a href="#">
												<i className="fa fa-long-arrow-right"></i>
											</a>
										</li>
										<li>courses</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="bleezy-about-page section_50">
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<div className="about-left">
								<h2>Courses</h2>
								<p>
									Our security officer training courses are
									designed to provide you with the knowledge
									and skills you need to protect yourself and
									others.
								</p>
								<p>
									Our experienced instructors use a
									combination of classroom instruction and
									hands-on training to ensure that you are
									fully prepared to handle any situation
								</p>
								<p>
									We provide training for individuals at all
									levels of experience, from those just
									starting their careers in security to
									seasoned professionals looking to advance
									their skills
								</p>
								<p>
									Our courses emphasize the importance of
									teamwork and collaboration, as well as
									effective communication and decision-making
									skills
								</p>
							</div>
						</div>
						<div className="col-md-6">
							<div className="about-right">
								<img src={imageUrl} alt="about image" />
							</div>
						</div>
					</div>

					<div className="row course-dates" id="course-dates">
						<h2>Course Dates</h2>

						{currentUser && (
							<div style={{ marginBottom: 10 }}>
								<button
									style={{
										backgroundColor: "#f26723",
										border: "none",
										color: "white",
									}}
									onClick={handleClickAddCourse}
								>
									Add course
								</button>
							</div>
						)}

						{courses.length === 0 ? (
							<section className="bleezy-blog-page-area section_100">
								<div className="container">
									<div className="row">
										<div
											className="col-md-12"
											style={{
												display: "flex",
												justifyContent: "center",
												minHeight: 200,
											}}
										>
											<div className="lds-ripple ">
												<div></div>
												<div></div>
											</div>
										</div>
									</div>
								</div>
							</section>
						) : (
							<>{renderedItems}</>
						)}
					</div>
				</div>
			</section>
		</>
	);
};

const styles = {
	input: {
		padding: 4,
		border: "1px solid #ccc",
	},
};
