export const modalStyle = {
	overlay: {
		backgroundColor: "rgba(0,0,0,0.2)",
	},
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
	},
};
